import React,{useEffect, useState} from "react";
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import { DateRangeInput } from "@blueprintjs/datetime";
import moment from "moment";
import { getlocationdata } from "../../services/DashboardService";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { NotificationManager } from "react-notifications";
import {  COMMON_MESSAGES } from "../../config/AppConfig";
import {LocationWisePurchase} from '../../services/LocationWisePurchase';
import {ListPurchase} from "../../services/PurchaseService"
import "../../assets/css/dashboard.css";
import PieChart from "../../components/Charts/PieChart";


const PurchaseView = () => {


    //const [entereddate,setEnteredDate]= useState([new Date(), new Date()]);
    const [locationList, setLocationList] = useState([]);

    const[totalStats,setTotalStats] = useState([]);
    const [totalStatsDate,setTotalStatsDate]= useState([new Date("01-01-2021"), new Date()]);

    const [purchaseSummaryDate,setPurchaseSummaryDate]= useState([new Date("01-01-2021"), new Date()]);
    const [purchaseSummary,setPurchaseSummary]= useState([]);
    const [locationWisePurchase, setLocationWisePurchase]= useState([]);
    const [selectedLocation, setSelectedLocation]= useState([]);



    let TotalStatsdata = new FormData();       // Top Selling Product Param Variable Data
    TotalStatsdata.set('startDate', totalStatsDate[0].getFullYear() +"-"+ totalStatsDate[0].getMonth() +"-"+ totalStatsDate[0].getDate());
    TotalStatsdata.set('endDate', totalStatsDate[1].getFullYear() +"-"+ totalStatsDate[1].getMonth() +"-"+ totalStatsDate[1].getDate());
    TotalStatsdata.set('locationid', 147);
    TotalStatsdata.set('admcompanyid', 5);
    TotalStatsdata.set('isAllCkecked', 0);

    let PurchaseSummarydata = new FormData();       
    PurchaseSummarydata.set('startDate', purchaseSummaryDate[0].getFullYear() +"-"+ purchaseSummaryDate[0].getMonth() +"-"+ purchaseSummaryDate[0].getDate());
    PurchaseSummarydata.set('endDate', purchaseSummaryDate[1].getFullYear() +"-"+ purchaseSummaryDate[1].getMonth() +"-"+ purchaseSummaryDate[1].getDate());
    PurchaseSummarydata.set('admcompanyid', 5);
    PurchaseSummarydata.set('isAllCkecked', 0);

   
    useEffect(()=>{
      let temp = [];
      selectedLocation.map(val => temp.push(val.locationid));
      TotalStatsdata.set('locationid', temp);

      LocationWisePurchase(TotalStatsdata).then((res) => {
            setTotalStats(res.totalamount);
            setLocationWisePurchase(res.locationwisepurchase.map(({ name, value }) => ({ "id": name, "label": name, "value": value })))
            });
    },[totalStatsDate,selectedLocation])

    useEffect(()=>{
      let temp = [];
      selectedLocation.map(val => temp.push(val.locationid));
      PurchaseSummarydata.set('locationid', temp);

      ListPurchase(PurchaseSummarydata).then((res) => {
                setPurchaseSummary(res.purchasedata)
                console.log(purchaseSummary)
            });
    },[purchaseSummaryDate,selectedLocation])

  

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    // var settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //   };

      useEffect(() => {
        getlocationdata().then((res) => {
          console.log(res)
          setLocationList(res.message);
        }).catch((err) => {    
            NotificationManager.error(COMMON_MESSAGES.SOMETHING_WRONG, 'Error', 5000);
        });
      },[]);


    return(
        <main role="main" className="main-content">
            <div className="container-fluid">
                <div className="row justify-content-center mb-3">
                    <div className="col-12">
                        <div className="outlet_datearea">
                            <h1>Outlet Wise Purchase</h1>
                            <div className="outlettotal">{totalStats}</div>
                        </div>
                        <div className="title_datearea">
                            <div className="allselected">
                            <Autocomplete                           ////// LOCATION SELECTION
                                multiple
                                id="checkboxes-tags-demo"
                                options={locationList}
                                disableCloseOnSelect
                                onChange={ (event,values) => setSelectedLocation(values) }
                                getOptionLabel={(option) => option.locationname}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.locationname}
                                    </React.Fragment>
                                )}
                                
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="All Select"  />
                                )}
                            />
                            </div>


                            <div className="datearea">             
                                <div className="iconarea"> 
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('MMM YY')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setTotalStatsDate(date)}
                                singleMonthOnly= {true}
                                value={totalStatsDate} />
                                
                                </div>
                            </div>
                        </div>
                        <div className="sliderarea">
                        {(locationWisePurchase.length===0 ? <h4>Data Not Found</h4> : <div style={{ width: 400, height: 300 }}> <PieChart orderTypeData={locationWisePurchase}/></div>)}
                        </div>


                        <div className="title_datearea">
                            <h2>Purchase Summary</h2>
                            <div className="datearea">
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('MMM DD')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setPurchaseSummaryDate(date)}
                                singleMonthOnly= {true}
                                value={purchaseSummaryDate} />
                                </div>
                            </div>
                            {(purchaseSummary.length===0 ? <h4>Data Not Found</h4> : <table><tbody>{purchaseSummary.map(d => <tr key={d.suppliername}><td><span></span>{d.suppliername}</td><td>{d.totalpo}</td><td>{d.totalamount}</td></tr>)}</tbody></table>)}
                        </div>



                        {/* <div className="title_datearea">
                            <h2>order type summary</h2>
                            <div className="datearea">
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('DD-MM-YYYY')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setOrderTypeDate(date)}
                                singleMonthOnly= {true}
                                value={orderTypeDate} />
                            </div>
                            </div>
                            {console.log(ordertype)}
                            {(ordertype.length===0 ? <h4>Data Not Found</h4> : <div style={{ width: 400, height: 300 }}> <PieChart orderTypeData={ordertype}/></div>)}
                            </div> */}


                        {/* <div className="title_datearea">
                            <h2>Payment Summary</h2>
                            <div className="datearea">
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('DD-MM-YYYY')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setPaymentTypeDate(date)}
                                singleMonthOnly= {true}
                                value={paymentTypeDate} />
                                
                                </div>
                            </div>
                            <ChartComponent paymentTypeName={paymentTypeName} paymentTypeValue={paymentTypeValue}/>

                        </div> */}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default PurchaseView;

