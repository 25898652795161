import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { RoutePaths } from "../config/RoutePathConfig";
import Sales from "../assets/img/sales.svg";
import Purchase from "../assets/img/purchase.svg";
import Expense from "../assets/img/expense.svg";
import Misc from "../assets/img/misc.svg";



const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 100,
  },
});

const FooterComponent = (props) => {
    const { shouldRender } = props;
// export default function LabelBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState('recents');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // return(
  //   <div className="MuiBottomNavigation-root">
  //       <button className="" type="button">
  //         <span className="MuiBottomNavigationAction-wrapper">
  //           <span className="MuiSvgIcon-root icon-sales"></span>
  //           <span className="MuiBottomNavigationAction-label MuiBottomNavigationAction-iconOnly">sales</span>
  //         </span>
  //       </button>
  //   </div>
  // );

  return !shouldRender ? (
    <></>
  ) : (
    <BottomNavigation value={value} onChange={handleChange} className={classes.root}>
      <BottomNavigationAction label="Sales" value="Sales" component={Link} to={RoutePaths.SALES} icon={<Icon >{<img className={classes.imageIcon} src={Sales} alt="Sales" />}</Icon>} />
      <BottomNavigationAction label="Purchase" value="Purchase" component={Link} to={RoutePaths.PURCHASE} icon={<Icon >{<img className={classes.imageIcon} src={Purchase} alt="Purchase" />}</Icon>} />
      <BottomNavigationAction label="Expense" value="Expense" component={Link} to={RoutePaths.EXPENSE}icon={<Icon >{<img className={classes.imageIcon} src={Expense}  alt="Expense" />}</Icon>} />
      <BottomNavigationAction label="Misc" value="Misc" component={Link} to={RoutePaths.MISC} icon={<Icon >{<img className={classes.imageIcon} src={Misc} alt="Misc" />}</Icon>} />
    </BottomNavigation>
  );
}

export default FooterComponent;