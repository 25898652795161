import React,{useEffect, useState} from "react";
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import { DateRangeInput } from "@blueprintjs/datetime";
import moment from "moment";
import { getlocationdata } from "../../services/DashboardService";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { NotificationManager } from "react-notifications";
import {  COMMON_MESSAGES } from "../../config/AppConfig";
import "../../assets/css/dashboard.css";
//import PieChart from "../../components/Charts/PieChart";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {CustomerSummary} from "../../services/CustomerSummary";
import { BillingSummary } from "../../services/BillingSummary";

const MiscView = () => {

    const [entereddate,setEnteredDate]= useState([new Date("01-01-2021"), new Date()]);
    const [locationList, setLocationList] = useState([]);
    const [selectedLocation, setSelectedLocation]= useState([]);

    //const percentage = 66;
    const [totalCustomer, setTotalCustomer] = useState([]);
    const [newCustomer, setNewCustomer] = useState([]);
    const [repeatCustomer, setRepeatCustomer] = useState([]);
    

    const [cancelBill, setCancelBill] = useState([]);
    const [reprintBill, setReprintBill] = useState([]);
    const [freeBill, setFreeBill] = useState([]);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    let CustomerData = new FormData();       
    CustomerData.set('startDate', entereddate[0].getFullYear() +"-"+ entereddate[0].getMonth() +"-"+ entereddate[0].getDate());
    CustomerData.set('endDate', entereddate[1].getFullYear() +"-"+ entereddate[1].getMonth() +"-"+ entereddate[1].getDate());
    CustomerData.set('admcompanyid', 5);

    let BillData = new FormData();       
    BillData.set('startDate', entereddate[0].getFullYear() +"-"+ entereddate[0].getMonth() +"-"+ entereddate[0].getDate());
    BillData.set('endDate', entereddate[1].getFullYear() +"-"+ entereddate[1].getMonth() +"-"+ entereddate[1].getDate());
    BillData.set('admcompanyid', 5);

    useEffect(() => {
        getlocationdata().then((res) => {
          //console.log(res)
          setLocationList(res.message);
        }).catch((err) => {    
            NotificationManager.error(COMMON_MESSAGES.SOMETHING_WRONG, 'Error', 5000);
        });
      },[]);

      useEffect(()=>{
        let temp = [];
        selectedLocation.map(val => temp.push(val.locationid));
        CustomerData.set('locationid', temp);
  
        CustomerSummary(CustomerData).then((res) => {
           setTotalCustomer(res.totalcustomer);  
           setNewCustomer(res.newcustomer);       
           setRepeatCustomer(res.repeatcustomer);       

            
        });
      },[entereddate,selectedLocation])
  
      useEffect(()=>{
        let temp = [];
        selectedLocation.map(val => temp.push(val.locationid));
        BillData.set('locationid', temp);
  
        BillingSummary(BillData).then((res) => {
           setCancelBill(res.cancelorder);  
           setReprintBill(res.reprintorder);       
           setFreeBill(res.freeorder);       
            //console.log(res)
            
        });
      },[entereddate,selectedLocation])

    return(
        <main role="main" className="main-content">
            <div className="container-fluid">
                <div className="row justify-content-center mb-3">
                    <div className="col-12">
                        <div className="outlet_datearea">
                            <h1>Reports</h1>
                            {/* <div className="outlettotal">265218</div> */}
                        </div>
                        <div className="title_datearea">
                            <div className="allselected">
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={locationList}
                                disableCloseOnSelect
                                onChange={ (event,values) => setSelectedLocation(values) }
                                getOptionLabel={(option) => option.locationname}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.locationname}
                                    </React.Fragment>
                                )}
                                
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="All Select"  />
                                )}
                            />
                            </div>
                            <div className="datearea">
                            <div className="outlet_datearea">
                            <h1>Customer Report</h1>
                            {/* <div className="outlettotal">265218</div> */}
                        </div >
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('MMM DD')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setEnteredDate(date)}
                                singleMonthOnly= {true}
                                value={entereddate} 
                                />                                
                                </div>
                            </div>
                        </div>
                        <div className="sliderarea">
                       
                        <div style={{height: '80%', width: '80%', display: 'flex', padding: '1%'}}>
                        <CircularProgressbar value={totalCustomer} text={`${totalCustomer}%`}/>
                        <CircularProgressbar value={newCustomer} text={`${newCustomer}%`} />
                        <CircularProgressbar value={repeatCustomer} text={`${repeatCustomer}%`} />

                        </div>
                        </div>

                        <div className="title_datearea">
                            <h2>Bill Stats</h2>
                            <div className="datearea">
                            <div style={{height: '80%', width: '80%', display: 'flex', padding: '1%'}}>
                                <CircularProgressbar value={cancelBill} text={`${cancelBill}%`} background
        backgroundPadding={6}
        styles={buildStyles({
          backgroundColor: "#3e98c7",
          textColor: "#fff",
          pathColor: "#fff",
          trailColor: "transparent"
        })}/>
                                <CircularProgressbar value={reprintBill} text={`${reprintBill}%`} background
        backgroundPadding={6}
        styles={buildStyles({
          backgroundColor: "#77B6D7",
          textColor: "#fff",
          pathColor: "#fff",
          trailColor: "transparent"
        })}/>
                                <CircularProgressbar value={freeBill} text={`${freeBill}%`} background Padding={6}
        styles={buildStyles({
          backgroundColor: "#B1D5E8",
          textColor: "#fff",
          pathColor: "#fff",
          trailColor: "transparent"
        })}/>
                            </div>
                            </div>
                        </div>


{/* 
                        <div className="title_datearea">
                            <h2>top selling products</h2>
                            <div className="datearea">
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('DD-MM-YYYY')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setEnteredDate(date)}
                                singleMonthOnly= {true}
                                value={entereddate} />
                                
                                </div>
                            </div>
                        </div>


                        <div className="title_datearea">
                            <h2>order type summary</h2>
                            <div className="datearea">
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('DD-MM-YYYY')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setEnteredDate(date)}
                                singleMonthOnly= {true}
                                value={entereddate} />
                                
                                </div>
                            </div>
                        </div>
                        <div className="title_datearea">
                            <h2>Payment Summary</h2>
                            <div className="datearea">
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('DD-MM-YYYY')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setEnteredDate(date)}
                                singleMonthOnly= {true}
                                value={entereddate} />
                                
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default MiscView;

