import React from "react";
import clsx from 'clsx';
import { Link } from "react-router-dom";
import { RoutePaths } from "../config/RoutePathConfig";

import logoImg from "../assets/img/logo.png";
import avatar from "../assets/img/avatar.png";
import "../../src/assets/css/header.css";
import "../../src/assets/css/icon.css";

import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import menuicon from "../../src/assets/img/menu.svg";

/*mamish */
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const getSvgIconMaterial = (Icon) => {
  return (
      <SvgIcon component="object">
          <embed type="image/svg+xml" src={Icon} style={{ height: "100%" }} />
      </SvgIcon>
  );
};
/*manish */

const HeaderComponent = () => {
  // Set state
  //const [displayName, setDisplayName] = useState("Guest");

 

  // Toggle sidebar menu
  // const handleSidebar = () => {
  //   if (document.querySelector("body").classList.contains("collapsed")) {
  //     document.querySelector("body").classList.remove("collapsed");
  //   } else {
  //     document.querySelector("body").classList.add("collapsed");
  //   }
  // };

/*manish */
const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            
            <ListItemText primary={text} />
          </ListItem>
        ))} */}
        <ListItem button primary="Dashboard" component={Link} to={RoutePaths.DASHBOARD}>Dashboard</ListItem>
        <ListItem button primary="Sales" component={Link} to={RoutePaths.SALES}>Sales</ListItem>
        <ListItem button primary="Purchase" component={Link} to={RoutePaths.PURCHASE}>Purchase</ListItem>
        <ListItem button primary="Expense" component={Link} to={RoutePaths.EXPENSE}>Expense</ListItem>
        <ListItem button primary="Misc" component={Link} to={RoutePaths.MISC}>Misc</ListItem>

      </List>
      
      {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );
  
/*manish */


  return (
    <nav className="topnav navbar navbar-light border-bottom bg-gray fixed-top">
      
      {['left'].map((anchor) => (
        <React.Fragment key="humber menu">
          <div className="leftarea">
          <Button onClick={toggleDrawer(anchor, true)}><img src={menuicon} alt="" /></Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
          <Link className="logo my-2" to=""><img src={logoImg} className="" alt="" /></Link>
          </div>
          <div className="rightarea">
          <ul className="nav profilearea">
          {/* <li className="nav-item">
          <Link className="profile_area my-2" to="">
            <span className="count">30</span>
            <span className="icon-notification"></span>
          </Link>
          </li> */}
            <li className="nav-item">
           
                <img component={Link} className="nav-link my-2 profile_area" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} src={avatar} alt="" />
            
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem component={Link} to={RoutePaths.PROFILE}>Profile</MenuItem>
                <MenuItem component={Link} to={RoutePaths.LOGOUT}>Logout</MenuItem>
              </Menu>
              {/* <Link className="nav-link my-2 profile_area" to={RoutePaths.PROFILE}>
                <img src={avatar} alt="" />
              </Link> */}
            </li>
          </ul>
          </div>
        </React.Fragment>
      ))}
  
    </nav>
    
  );


};

export default HeaderComponent;

