import { getApiCall } from "../utils/Api";

export const getlocationdata = async () => {
  try {
    //let API_URL = `${process.env.REACT_MIDDLEWARE}`
    //console.log(API_URL)
    const result = await getApiCall(`/api/v1/external/getlocationdata`, true);
   // console.log(result.data)
    return result.data;
  } catch (e) {
    throw e;
  }
};