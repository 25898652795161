import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { RoutePaths } from "../config/RoutePathConfig";
import PageNotFoundComponent from "../components/PageNotFoundComponent";
import LoginView from "../views/login/LoginView";
import LogoutView from "../views/profile/LogoutView";
import ProfileView from "../views/profile/ProfileView";
import DashboardView from "../views/dashboard/DashboardView";
import SalesView from "../views/dashboard/SalesView";
import PurchaseView from "../views/dashboard/PurchaseView";
import ExpenseView from "../views/dashboard/ExpenseView";
import MiscView from "../views/dashboard/MiscView";


const AppRoutes = (props) => {
  const { isLoggedIn, updateLoggedIn } = props;

  return (
    <Switch>
      <Route
        path={RoutePaths.LOGIN}
        exact
        render={(props) => {
          if (isLoggedIn) {
            return <Redirect to={RoutePaths.DASHBOARD} />;
          } else {
            return <LoginView {...props} updateLoggedIn={updateLoggedIn} />;
          }
        }}
      />
      <Route
        path={RoutePaths.DASHBOARD}
        exact
        render={(props) => {
          if (isLoggedIn) {
            return <DashboardView {...props} />;
          } else {
            return <Redirect to={RoutePaths.LOGIN} />;
          }
        }}
      />
      <Route
        path={RoutePaths.PROFILE}
        exact
        render={(props) => {
          if (isLoggedIn) {
            return <ProfileView {...props} />;
          } else {
            return <Redirect to={RoutePaths.LOGIN} />;
          }
        }}
      />
      <Route
        path={RoutePaths.LOGOUT}
        exact
        render={(props) => {
          if (isLoggedIn) {
            return <LogoutView {...props} updateLoggedIn={updateLoggedIn} />;
          } else {
            return <Redirect to={RoutePaths.LOGIN} />;
          }
        }}
      />
      <Route
        path={RoutePaths.SALES}
        exact
        render={(props) => {
          if (isLoggedIn) {
            return <SalesView {...props} updateLoggedIn={updateLoggedIn} />;
          } else {
            return <Redirect to={RoutePaths.LOGIN} />;
          }
        }}
      />
      <Route
        path={RoutePaths.PURCHASE}
        exact
        render={(props) => {
          if (isLoggedIn) {
            return <PurchaseView {...props} updateLoggedIn={updateLoggedIn} />;
          } else {
            return <Redirect to={RoutePaths.LOGIN} />;
          }
        }}
      />
      <Route
        path={RoutePaths.EXPENSE}
        exact
        render={(props) => {
          if (isLoggedIn) {
            return <ExpenseView {...props} updateLoggedIn={updateLoggedIn} />;
          } else {
            return <Redirect to={RoutePaths.LOGIN} />;
          }
        }}
      />
      <Route
        path={RoutePaths.MISC}
        exact
        render={(props) => {
          if (isLoggedIn) {
            return <MiscView {...props} updateLoggedIn={updateLoggedIn} />;
          } else {
            return <Redirect to={RoutePaths.LOGIN} />;
          }
        }}
      />
      <Route
        path="*"
        render={(props) => {
          return <PageNotFoundComponent isLoggedIn={isLoggedIn} />;
        }}
      />
    </Switch>
  );
};

export default AppRoutes;
