import { postApiCall} from "../utils/Api";

export const ListTotalTSP = async (data) => {
    try {
      //console.log(data);
      const result = await postApiCall(`/api/v1/dashboard/topsellingproductssummary`,data,true);
      var apiresult = JSON.parse(result.data.message);
      //console.log(apiresult)
      return apiresult;
    } catch (e) {
      throw e;
    }
  };