
import React,{useEffect, useState} from "react";
import SvgIcon from '@material-ui/core/SvgIcon';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import { DateRangeInput } from "@blueprintjs/datetime";
import moment from "moment";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { NotificationManager } from "react-notifications";
import {  COMMON_MESSAGES } from "../../config/AppConfig";
import { getlocationdata } from "../../services/DashboardService";
import "../../assets/css/dashboard.css";
//import calender from "../../assets/img/calender.svg";
import Righticon from "../../assets/img/rightar.svg";
import {ListTotalStats} from "../../services/TotalStatsService";
import {RevenueSummary} from "../../services/RevenueSummary";


const DashboardView = () => {

  const [totalSalesStats, setTotalSalesStats] = useState();
  const [totalPurchaseStats, setTotalPurchaseStats] = useState();
  const [totalExpenseStats, setTotalExpenseStats] = useState();
  const [entereddate,setEnteredDate]= useState([new Date("01-01-2021"), new Date()]);

  const [totalCash, setTotalCash] = useState();
  const [totalCredit, setTotalCredit] = useState();
  //const [totalSalesStats, setTotalSalesStats] = useState();


  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation]= useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  let data = new FormData();
  data.set('startDate', entereddate[0].getDate() +"-"+ entereddate[0].getMonth() +"-"+ entereddate[0].getFullYear());
  data.set('endDate', entereddate[1].getDate() +"-"+ entereddate[1].getMonth() +"-"+ entereddate[1].getFullYear());
  data.set('admcompanyid', 5);
  //data.set('isAllCkecked', 0);


  useEffect(() => {
    getlocationdata().then((res) => {
      //console.log(res)
      setLocationList(res.message);
    }).catch((err) => {    
      NotificationManager.error(COMMON_MESSAGES.SOMETHING_WRONG, 'Error', 5000);
    });
  },[]);


  const getSvgIconMaterial = (Icon) => {
    return (
        <SvgIcon component="object">
            <embed type="image/svg+xml" src={Icon} style={{ height: "100%" }} />
        </SvgIcon>
    );
};


  
useEffect(() => {

  let temp = [];
  selectedLocation.map(val => temp.push(val.locationid));
  data.set('locationid', temp);

  ListTotalStats(data).then((res) => {
  setTotalSalesStats(res.getBillSummary[0].income);
  setTotalExpenseStats(res.getexpenseSummary[0].totalamt);
  setTotalPurchaseStats(res.getPurchaseSummary[0].grandtotal);

},[entereddate, selectedLocation])});

useEffect(() => {

  let temp = [];
  selectedLocation.map(val => temp.push(val.locationid));
  data.set('locationid', temp);

  RevenueSummary(data).then((res) => {
    setTotalCash(res.cash[0]);
    setTotalCredit(res.cradit[0]);
  //console.log(res);

},[entereddate, selectedLocation]);

});
  return (
    <main role="main" className="main-content">
      <div className="container-fluid">
        <div className="row justify-content-center mb-3">
          <div className="col-12">
            <div className="title_datearea">
              <h1>Dashboard</h1>
              <div className="datearea">
                <div className="iconarea">
                <DateRangeInput
                  formatDate={date => moment(date.toLocaleString()).format('MMM DD')}
                  parseDate={str => new Date(str)}
                  onChange={(date) => setEnteredDate(date)}
                  defaultValue={[new Date("01-01-2021"), new Date()]}/>
                </div>
              </div>

              {/* Location Menu */}
            </div>
                <div className="allselected">
                    <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={locationList}
                    disableCloseOnSelect
                    onChange={ (event,values) => setSelectedLocation(values) }
                    getOptionLabel={(option) => option.locationname}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}/>
                        {option.locationname}
                      </React.Fragment>)}
                                
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="All Select"  />
                      )}/>
                  </div>
              
            
            <div className="sales_aera">
            <div className="boxarea">
              <div className="box_area">
                <div className="salescount">
                  <h2>Sales</h2>
                  <p>{totalSalesStats}</p>
                </div>
                <div className="expensecount">
                  <h2>expense</h2>
                  <p>{totalExpenseStats}</p>
                </div>
                <div className="purchasecount">
                  <h2>purchase</h2>
                  <p>{totalPurchaseStats}</p>
                </div>
              </div>
              
            </div>
              <p className="scrolltext">
                scroll right
                {getSvgIconMaterial(Righticon)}
                </p>
            </div>
            <div className="title_datearea">
              <h1>Weekly summary</h1>
              <div className="datearea">
                <div className="iconarea">
                {/* <DateRangeInput
                  formatDate={date => moment(date.toLocaleString()).format('DD-MM-YYYY')}
                  parseDate={str => new Date(str)}
                  onChange={(date) => {setEnteredStartDate(date[0].getDay()+"-"+date[0].getMonth()+"-"+date[0].getFullYear()); setEnteredEndDate(date[1].getDay()+"-"+date[1].getMonth()+"-"+date[1].getFullYear());}}
                  />
                  {getSvgIconMaterial(calender)} */}
                </div>
              </div>
            </div>
            <div className="weeklychart">{/*insert weekly Bar Chart*/}</div>
            <div className="totalvstotal">
              <div className="totalcash">
                <div className="total_cash">
                  <div className="ruppes">
                    <div className="gradient-border">
                      <span className="icon-indianr">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                        <span className="path6"></span>
                      </span>
                    </div>
                    
                  </div>
                  <div className="txt_total">
                    <h2>Total Cash</h2>
                    <p>{totalCash}</p>
                  </div>
                </div>
                <div className="prev_cash"></div>
              </div>
              <div className="totalcredits">
              <div className="total_cash">
                  <div className="ruppes">
                    <div className="gradient-border creditarea">
                      <span className="icon-credit">
                        
                      </span>
                    </div>
                    
                  </div>
                  <div className="txt_total">
                    <h2>Total Credits</h2>
                    <p>{totalCredit}</p>
                  </div>
                </div>
                <div className="prev_cash"></div>
              </div>
              
            </div>
          </div>
        </div>
       
      </div>
    </main>
  );
};

export default DashboardView;
