import React, { useEffect } from "react";
import { logoutUser } from "../../session/UserSession";
import LoaderComponent from "../../components/LoaderComponent";

const LogoutView = (props) => {
  const { updateLoggedIn } = props;

  useEffect(() => {
    logoutUser();
    updateLoggedIn(false);
  });

  return <LoaderComponent />;
};

export default LogoutView;
