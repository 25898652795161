// import React, { useState, useEffect} from "react";
// import ChartComponent from "../../components/Charts/ChartComponent";
// import {ListExpense} from '../../services/ExpenseService';
// import {ListTotalStats} from '../../services/TotalStatsService';

// const ExpenseView = (props) => {

//   //var Expdata = [];
//   const ReportTitle= "Expense Summary";

//   const[totalExpense, setTotalExpense] = useState([]);
//   const[totalStats, setTotalStats] = useState();
//   const[startDate,setStartDate]= useState(props.date[0].getFullYear() +"-"+ props.date[0].getMonth() +"-"+ props.date[0].getDate());
//   const[endDate,setEndDate]=useState(props.date[1].getFullYear() +"-"+ props.date[1].getMonth() +"-"+ props.date[1].getDate());
//   //const[startDate,setStartDate]= useState("2020-01-01");
//   //const[endDate,setEndDate]=useState("2021-01-01");

//   let data = new FormData();
//   data.set('startDate', startDate);
//   data.set('endDate', endDate);
//   data.set('locationid', props.Location);
//   data.set('admcompanyid', 5);
//   data.set('isAllCkecked', 0);

//   useEffect(() => {
//     //console.log(props.Location.id);
//       setStartDate(props.date[0].getFullYear() +"-"+ props.date[0].getMonth() +"-"+ props.date[0].getDate());
//       setEndDate(props.date[1].getFullYear() +"-"+ props.date[1].getMonth() +"-"+ props.date[1].getDate());
//       //setStartDate("2020-01-01");
//       //setEndDate("2021-01-01");
//       //console.log(data)
//       ListExpense(data).then((res) => {
//         if(res==="")
//         {
//           setTotalExpense("No Data Found");
//         }
//         else{
//           //res.map((data) => {Expdata.push({"expname":data.expdisplayname,"expamount":data.totalamount});});
//           //setTotalExpense(res.expdisplayname);
//           //res.map(data => (setExpNameList(data.expdisplayname)));
//           //setExpNameList(res.expdisplayname);
//           res.map(data => totalExpense.push({"expname": data.expdisplayname, "expamount": data.totalamount}))
//           //console.log(totalExpense)
//         }
//       });

//       ListTotalStats(data).then((res) => {
//         if(res==="")
//         {
//           setTotalStats("No Data Found");
//         }
//         else{
//           setTotalStats(res.getexpenseSummary[0].totalamt);
//         }

//       });
//   },[props]);
//   return (
//     <div className="card-body">
//       <div className="row align-items-center justify-content-center">
//         {ReportTitle}
//       </div>
//       <div><h3>Total Expense</h3>{totalStats}</div>
//       <div><h3>Summary</h3></div>
//       <div>{totalExpense.map(d => <li key={d.expname}>{d.expname} {d.expamount}</li>)} </div>
//       <ChartComponent totalexplabel={totalExpense}/>
//     </div>
//   );
// };

// export default ExpenseView;
import React,{useEffect, useState} from "react";
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import { DateRangeInput } from "@blueprintjs/datetime";
import moment from "moment";
import { getlocationdata } from "../../services/DashboardService";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { NotificationManager } from "react-notifications";
import {  COMMON_MESSAGES } from "../../config/AppConfig";
import {LocationWiseExpense} from '../../services/LocationWiseExpense';
import "../../assets/css/dashboard.css";
import PieChart from "../../components/Charts/PieChart";
import { ListExpense } from "../../services/ExpenseService";


const ExpenseView = () => {


    //const [entereddate,setEnteredDate]= useState([new Date(), new Date()]);
    const [locationList, setLocationList] = useState([]);
    const [selectedLocation, setSelectedLocation]= useState([]);
    const [locationWiseExpense, setLocationWiseExpense]= useState([]);


    const[totalStats,setTotalStats] = useState([]);
    const [totalStatsDate,setTotalStatsDate]= useState([new Date("01-01-2021"), new Date()]);

    const [expenseSummaryDate,setExpenseSummaryDate]= useState([new Date("01-01-2021"), new Date()]);
    const [expenseSummary,setExpenseSummary]= useState([]);


    let TotalStatsdata = new FormData();       
    TotalStatsdata.set('startDate', totalStatsDate[0].getFullYear() +"-"+ totalStatsDate[0].getMonth() +"-"+ totalStatsDate[0].getDate());
    TotalStatsdata.set('endDate', totalStatsDate[1].getFullYear() +"-"+ totalStatsDate[1].getMonth() +"-"+ totalStatsDate[1].getDate());
    TotalStatsdata.set('admcompanyid', 5);
    TotalStatsdata.set('isAllCkecked', 0);

    let ExpenseSummarydata = new FormData();       
    ExpenseSummarydata.set('startDate', expenseSummaryDate[0].getFullYear() +"-"+ expenseSummaryDate[0].getMonth() +"-"+ expenseSummaryDate[0].getDate());
    ExpenseSummarydata.set('endDate', expenseSummaryDate[1].getFullYear() +"-"+ expenseSummaryDate[1].getMonth() +"-"+ expenseSummaryDate[1].getDate());
    ExpenseSummarydata.set('admcompanyid', 5);
    ExpenseSummarydata.set('isAllCkecked', 0);

   
    useEffect(()=>{
      let temp = [];
      selectedLocation.map(val => temp.push(val.locationid));
      TotalStatsdata.set('locationid', temp);

      LocationWiseExpense(TotalStatsdata).then((res) => {
            setTotalStats(res.totalamount);
            setLocationWiseExpense(res.locationwiseexpense.map(({ name, value }) => ({ "id": name, "label": name, "value": value })))
            });
    },[totalStatsDate,selectedLocation])

    useEffect(()=>{
      let temp = [];
      selectedLocation.map(val => temp.push(val.locationid));
      ExpenseSummarydata.set('locationid', temp);

      ListExpense(ExpenseSummarydata).then((res) => {
                setExpenseSummary(res.expensedata)
            });
    },[expenseSummaryDate,selectedLocation])

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    // var settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //   };

      useEffect(() => {
        getlocationdata().then((res) => {
          //console.log(res)
          setLocationList(res.message);
        }).catch((err) => {    
            NotificationManager.error(COMMON_MESSAGES.SOMETHING_WRONG, 'Error', 5000);
        });
      },[]);


    return(
        <main role="main" className="main-content">
            <div className="container-fluid">
                <div className="row justify-content-center mb-3">
                    <div className="col-12">
                        <div className="outlet_datearea">
                            <h1>Outlet Wise Expense</h1>
                            <div className="outlettotal">{totalStats}</div>
                        </div>
                        <div className="title_datearea">
                            <div className="allselected">
                            <Autocomplete                           ////// LOCATION SELECTION
                                multiple
                                id="checkboxes-tags-demo"
                                options={locationList}
                                onChange={ (event,values) => setSelectedLocation(values) }
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.locationname}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.locationname}
                                    </React.Fragment>
                                )}
                                
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="All Select"  />
                                )}
                            />
                            </div>


                            <div className="datearea">             
                                <div className="iconarea"> 
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('MMM DD')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setTotalStatsDate(date)}
                                singleMonthOnly= {true}
                                value={totalStatsDate} />
                                
                                </div>
                            </div>
                        
                        <div className="sliderarea">
                        {(locationWiseExpense.length===0 ? <h4>Data Not Found</h4> : <div style={{ width: 400, height: 300 }}> <PieChart orderTypeData={locationWiseExpense}/></div>)}
                        </div>
                        </div>

                        <div className="title_datearea">
                            <h2>Expense Summary</h2>
                            <div className="datearea">
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('MMM DD')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setExpenseSummaryDate(date)}
                                singleMonthOnly= {true}
                                value={expenseSummaryDate} />
                                </div>
                            </div>
                            {(expenseSummary.length===0 ? <h4>Data Not Found</h4> : <table><tbody>{expenseSummary.map(d => <tr key={d.expdisplayname}><td><span></span>{d.expdisplayname}</td><td>{d.totalamount}</td></tr>)}</tbody></table>)}
                        </div>


                        {/* <div className="title_datearea">
                            <h2>order type summary</h2>
                            <div className="datearea">
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('DD-MM-YYYY')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setOrderTypeDate(date)}
                                singleMonthOnly= {true}
                                value={orderTypeDate} />
                            </div>
                            </div>
                            {console.log(ordertype)}
                            {(ordertype.length===0 ? <h4>Data Not Found</h4> : <div style={{ width: 400, height: 300 }}> <PieChart orderTypeData={ordertype}/></div>)}
                            </div> */}


                        {/* <div className="title_datearea">
                            <h2>Payment Summary</h2>
                            <div className="datearea">
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('DD-MM-YYYY')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setPaymentTypeDate(date)}
                                singleMonthOnly= {true}
                                value={paymentTypeDate} />
                                
                                </div>
                            </div>
                            <ChartComponent paymentTypeName={paymentTypeName} paymentTypeValue={paymentTypeValue}/>

                        </div> */}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default ExpenseView;

