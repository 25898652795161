import { postApiCall} from "../utils/Api";

export const LocationWiseExpense = async (data) => {
    try {
      const result = await postApiCall(`/api/v1/dashboard/getlocationwiseexpense`,data,true);
      var apiresult = JSON.parse(result.data.message);
      return apiresult;
    } catch (e) {
      throw e;
    }
  };