import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { isUserLoggedIn } from "../session/UserSession";
import AppRoutes from "../routes/AppRoutes";
import AfterLoginMenuComponent from "../components/AfterLoginMenuComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../assets/css/theme.css";
import 'react-notifications/lib/notifications.css';
import FooterComponent from "../components/FooterComponent";
 
const AppView = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(isUserLoggedIn());
 
  if (!isLoggedIn) {
    async function logout() {
     
    }
    logout();
  }
 
  return (
    <Router>
      <div className="wrapper">
        <AfterLoginMenuComponent shouldRender={isLoggedIn} />
        <AppRoutes
          isLoggedIn={isLoggedIn}
          updateLoggedIn={(data) => {
            setIsLoggedIn(data);
          }}
        />
        <FooterComponent shouldRender={isLoggedIn}/>
      </div>
    </Router>
  );
};
 
export default AppView;