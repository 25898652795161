import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RoutePaths } from "../../config/RoutePathConfig";
import { APP_NAME, COMMON_MESSAGES } from "../../config/AppConfig";
import { setUserDetails, setUserToken } from "../../session/UserSession";
import logoImg from "../../assets/img/logo.png";
import { login } from "../../services/LoginService";
import { NotificationManager } from "react-notifications";

const LoginView = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const updateLoggedIn = props.updateLoggedIn;
  let history = useHistory();

  const onSubmit = (data) => {
    setFormSubmitted(true);

    const loginData = {
      username: data.email,
      password: data.password
    };
    login(loginData).then((res) => {
      const result = JSON.parse(res.message);
      setUserDetails({name: result.data.username, locationid: result.data.locationid, companyid: result.data.companyid});
      setUserToken(result.token);
      history.push(RoutePaths.DASHBOARD);
      updateLoggedIn(true);
      setFormSubmitted(false);
    }).catch((err) => {    
      // if(err.response.data.message) {
      //   NotificationManager.error(err.response.data.message, 'Error', 5000);
      // } else {
      //   NotificationManager.error(COMMON_MESSAGES.SOMETHING_WRONG, 'Error', 5000);
      // }
      NotificationManager.error("Invalid credentials.", 'Error', 5000);
      history.push(RoutePaths.LOGIN);
      setFormSubmitted(false);
    });
  };

  return (
    <div className="vh-100">
      <div className="row align-items-center h-100">
        <form
          className="col-lg-3 col-md-4 col-10 mx-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mx-auto mt-2 mb-4 flex-fill text-center">
            <img src={logoImg} alt={APP_NAME} style={{width:"100%"}} />
          </div>
          <h1 className="h6 mb-4 text-center">Login to your account</h1>
          <div className="form-group">
            <label>
              Email<i className="text-danger">*</i>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={"form-control " + (errors.email && "is-invalid")}
              placeholder="Email address"
              ref={register({
                required: true,
                validate: (value) => {
                  return !!value.trim();
                },
              })}
            />
          </div>
          <div className="form-group">
            <label>
              Password<i className="text-danger">*</i>
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className={"form-control " + (errors.password && "is-invalid")}
              placeholder="Password"
              ref={register({ required: true })}
            />
          </div>
          <div className="form-group text-center mt-4">
            <button
              className="btn btn-primary"
              disabled={formSubmitted}
              type="submit"
            >
              {(formSubmitted && "Processing...") || "Login"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginView;
