import React from 'react';
//import { Card } from 'react-bootstrap';
import {Bar} from 'react-chartjs-2';

function ChartComponent(props){

  const data = {
    labels: props.paymentTypeName,
    datasets: [
      {
        label: 'Payment Types',
        data: props.paymentTypeValue,
        backgroundColor: [
          'rgba(0, 255, 255, 1)',
        ],
        borderRadius:70,
        borderColor: [
          'rgba(0, 255, 255, 1)',
        ],
        height: 200,
        borderWidth: 3,
      },
    ],
  };
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

return (<>
    <Bar data={data} options={options} />
  </>
)
}

export default ChartComponent;