// import React, { useState, useEffect} from "react";
// import {ListTotalStats} from '../../services/TotalStatsService';
// import {ListSales} from '../../services/SalesService';
// import { ListTotalTSP } from "../../services/TopSellingProduct";
// import { ListOrderTypeSummary} from "../../services/OrderTypeSummary";
// import { ListPaymentTypeSummary} from "../../services/PaymentTypeSummary";
// import PieChart from "../../components/Charts/PieChart";
// import ChartComponent from "../../components/Charts/ChartComponent";

// const SalesView = (props) => {

//   const ReportTitle= "Sales Summary";
//   const[totalSales, setTotalSales] = useState([]);
//   const[locationwiseTotalAmt, setLocationwiseTotalAmt] = useState(0);
//   const[totalStats, setTotalStats] = useState();
//   const[ordertype,setOrderType]= useState([]);
//   const[paymentTypeName,setPaymentTypeName]= useState([]);
//   const[paymentTypeValue,setPaymentTypeValue]= useState([]);
//   const[totalTSP,setTotalTSP] = useState([]);
//   const[location,setLocation]=useState([]);
//   const [temp,setTemp]=useState();
//   const[startDate,setStartDate]= useState(props.date[0].getFullYear() +"-"+ props.date[0].getMonth() +"-"+ props.date[0].getDate());
//   const[endDate,setEndDate]=useState(props.date[1].getFullYear() +"-"+ props.date[1].getMonth() +"-"+ props.date[1].getDate());


//   let data = new FormData();
//   data.set('startDate', startDate);
//   data.set('endDate', endDate);
//   data.set('locationid', location);
//   data.set('admcompanyid', 5);
//   data.set('isAllCkecked', 0);



//  useEffect(() => {

//   setStartDate(props.date[0].getFullYear() +"-"+ props.date[0].getMonth() +"-"+ props.date[0].getDate());
//   setEndDate(props.date[1].getFullYear() +"-"+ props.date[1].getMonth() +"-"+ props.date[1].getDate());


//     props.Location.map((d) => {location.push(d.locationid)});

//     ListTotalStats(data).then((res) => {
     
//         setTotalStats(res.getBillSummary[0].income);
  
//     });

//      ListSales(data).then((res) => {
     
//     //      //var obj = JSON.parse(res.message);
//     //      //console.log(res)
//             res.locationwisesales.map(data => totalSales.push({"name":data.name, "value":data.value}))

//     //      setLocationwiseTotalAmt(res.totalamount);
//     //      //setTotalSales(res.locationwisesales[0].name);        
//      });


//     ListTotalTSP(data).then((res) => {
//       //console.log(res)
    
//          //var obj = JSON.parse(res.message);
//          //console.log(res)
//         res.topselproducts.map(data => totalTSP.push({"productid": data.productid, "productname": data.productname,"totalproduct": data.totalproduct, "totalsales": data.totalsales}))
//         //res.topselproducts.map(data => setTotalTSP(prevState => [...prevState, {"productid": data.productid, "productname": data.productname,"totalproduct": data.totalproduct, "totalsales": data.totalsales}]))
        
//     });

//     ListOrderTypeSummary(data).then((res) => {
//       //console.log(res)
    
//           res.ordertype.map(data => ordertype.push({"id": data.name, "label": data.name, "value": data.value}))

//     });

//     ListPaymentTypeSummary(data).then((res) => {
//       if(res.paymenttype.length===0)
//         {
//           paymentTypeValue.push(null);
//           paymentTypeValue.push(null);
//         }
//         else{
//           res.paymenttype.map(data => {paymentTypeName.push(data.name); paymentTypeValue.push(data.value);})
//         }
//     });

//   });
   
//   return (
//     <div className="card-body">

//         <div className="row align-items-center justify-content-center">{ReportTitle}</div>
        
//         <div><h3>Total Sales</h3>{totalStats}</div>
    
//         <div><h3>Locationwise Total Amount</h3>{locationwiseTotalAmt}</div>
        
//         <div><h3>Summary</h3></div>
//         {(totalSales.length===0 ? <h4>Data Not Found</h4> : <table>{totalSales.map(d => <tr><td>{d.name}</td><td>{d.value}</td></tr>)}</table>)}
     
//         <div><h3>Top Selling Products</h3>
//         {(totalTSP.length===0 ? <h4>Data Not Found</h4> : <table>{totalTSP.map(d => <tr><td>{d.productname}</td><td>{d.totalproduct}</td></tr>)}</table>)}
     
//         <div><h3>Order Type Summary</h3>
//         <div style={{ width: 400, height: 300 }}>
//         <PieChart orderTypeData={ordertype}/>
//         </div>
     
//         <div><ChartComponent paymentTypeName={paymentTypeName} paymentTypeValue={paymentTypeValue}/></div>
     
//     </div>
//     </div>
//     </div>
//   );
// };


// export default SalesView;
import React,{useEffect, useState} from "react";
//import Slider from "react-slick";
//import { RoutePaths } from "../../config/RoutePathConfig";
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import { DateRangeInput } from "@blueprintjs/datetime";
import moment from "moment";
import { getlocationdata } from "../../services/DashboardService";

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { NotificationManager } from "react-notifications";
import {  COMMON_MESSAGES } from "../../config/AppConfig";
import {ListTotalTSP} from "../../services/TopSellingProduct";
import { ListPaymentTypeSummary} from "../../services/PaymentTypeSummary";
import ChartComponent from "../../components/Charts/ChartComponent";
import { ListOrderTypeSummary} from "../../services/OrderTypeSummary";
import PieChart from "../../components/Charts/PieChart";
import {ListSales} from '../../services/SalesService';
import "../../assets/css/dashboard.css";

const SalesView = () => {


    const [entereddate,setEnteredDate]= useState([new Date("01-01-2021"), new Date()]);
    const [locationList, setLocationList] = useState([]);
    const [selectedLocation, setSelectedLocation]= useState([]);


    const[totalTSP,setTotalTSP] = useState([]);
    const [tSPDate,setTSPDate]= useState([new Date("01-01-2021"), new Date()]);

    const paymentTypeName = [];
    const paymentTypeValue = [];
    const [paymentTypeDate,setPaymentTypeDate]= useState([new Date("01-01-2021"), new Date()]);

    const [ordertype,setOrderType]= useState([]);
    const [orderTypeDate, setOrderTypeDate]= useState([new Date("01-01-2021"), new Date()]);

    const[totalStats,setTotalStats] = useState([]);
    const totalStatsDate = [new Date("01-01-2021"), new Date()];

    let TotalStatsdata = new FormData();       // Top Selling Product Param Variable Data
    TotalStatsdata.set('startDate', totalStatsDate[0].getFullYear() +"-"+ totalStatsDate[0].getMonth() +"-"+ totalStatsDate[0].getDate());
    TotalStatsdata.set('endDate', totalStatsDate[1].getFullYear() +"-"+ totalStatsDate[1].getMonth() +"-"+ totalStatsDate[1].getDate());
    TotalStatsdata.set('admcompanyid', 5);
    TotalStatsdata.set('isAllCkecked', 0);

    let TSPdata = new FormData();       // Top Selling Product Param Variable Data
    TSPdata.set('startDate', tSPDate[0].getFullYear() +"-"+ tSPDate[0].getMonth() +"-"+ tSPDate[0].getDate());
    TSPdata.set('endDate', tSPDate[1].getFullYear() +"-"+ tSPDate[1].getMonth() +"-"+ tSPDate[1].getDate());
    TSPdata.set('admcompanyid', 5);
    TSPdata.set('isAllCkecked', 0);

    let PaymentTypedata = new FormData();       // PAYMENT TYPE Param Variable Data
    PaymentTypedata.set('startDate', paymentTypeDate[0].getFullYear() +"-"+ paymentTypeDate[0].getMonth() +"-"+ paymentTypeDate[0].getDate());
    PaymentTypedata.set('endDate', paymentTypeDate[1].getFullYear() +"-"+ paymentTypeDate[1].getMonth() +"-"+ paymentTypeDate[1].getDate());
    PaymentTypedata.set('admcompanyid', 5);
    PaymentTypedata.set('isAllCkecked', 0);

    let orderTypedata = new FormData();       // Top Selling Product Param Variable Data
    orderTypedata.set('startDate', orderTypeDate[0].getFullYear() +"-"+ orderTypeDate[0].getMonth() +"-"+ orderTypeDate[0].getDate());
    orderTypedata.set('endDate', orderTypeDate[1].getFullYear() +"-"+ orderTypeDate[1].getMonth() +"-"+ orderTypeDate[1].getDate());
    orderTypedata.set('admcompanyid', 5);
    orderTypedata.set('isAllCkecked', 0);

   
    useEffect(()=>{
        let temp = [];
        selectedLocation.map(val => temp.push(val.locationid));
        TSPdata.set('locationid', temp);

        ListTotalTSP(TSPdata).then((res) => {       // Top Selling Product API Call
            setTotalTSP(res.topselproducts);
        });
    },[tSPDate,selectedLocation]);

    useEffect(()=>{
        let temp = [];
        selectedLocation.map(val => temp.push(val.locationid));
        PaymentTypedata.set('locationid', temp);
        ListPaymentTypeSummary(PaymentTypedata).then((res) => {
            if(res.paymenttype.length===0)
            {
                paymentTypeName.push(null);
                paymentTypeValue.push(null);
            }
            else{
                res.paymenttype.map(data => {paymentTypeName.push(data.name); paymentTypeValue.push(data.value);});
            }
        });
    },[paymentTypeDate,selectedLocation]);

    useEffect(()=>{
        let temp = [];
        selectedLocation.map(val => temp.push(val.locationid));
        orderTypedata.set('locationid', temp);
        
        ListOrderTypeSummary(orderTypedata).then((res) => {
                setOrderType(res.ordertype.map(({ value, name }) => ({ "id": name, "label": name, "value": value })));    
            });
    },[orderTypeDate,selectedLocation])


    // ListPaymentTypeSummary(PaymentTypedata).then((res) => {
    //     if(res.paymenttype.length===0)
    //       {
    //         paymentTypeValue.push(null);
    //         paymentTypeValue.push(null);
    //       }
    //       else{
    //         res.paymenttype.map(data => {paymentTypeName.push(data.name); paymentTypeValue.push(data.value);})
    //       }
    //   });


    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    // var settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //   };

      useEffect(() => {
        getlocationdata().then((res) => {
          setLocationList(res.message);
        }).catch((err) => {    
            NotificationManager.error(COMMON_MESSAGES.SOMETHING_WRONG, 'Error', 5000);
        });
      },[]);

      useEffect(()=>{
        let temp = [];
        selectedLocation.map(val => temp.push(val.locationid));
        TotalStatsdata.set('locationid', temp);
        ListSales(TotalStatsdata).then((res) => {
                if(res==="")
                {
                  setTotalStats("No Data Found");
                }
                else{
                  setTotalStats(res.totalamount);
                }
              // console.log(startDate);
              // console.log(endDate);
              });
      },[totalStatsDate,selectedLocation])


    return(
        <main role="main" className="main-content">
            <div className="container-fluid">
                <div className="row justify-content-center mb-3">
                    <div className="col-12">
                        <div className="outlet_datearea">
                            <h1>Outlet Wise Sales</h1>
                            <div className="outlettotal">{totalStats}</div>
                        </div>
                        <div className="title_datearea">
                            <div className="allselected">
                            <Autocomplete                           ////// LOCATION SELECTION
                                multiple
                                id="checkboxes-tags-demo"
                                options={locationList}
                                disableCloseOnSelect
                                onChange={ (event,values) => setSelectedLocation(values) }
                                getOptionLabel={(option) => option.locationname}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.locationname}
                                    </React.Fragment>
                                )}
                                
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="All Select"  />
                                )}
                            />
                            </div>


                            <div className="datearea">             
                                <div className="iconarea"> 
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('MMM DD')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setEnteredDate(date)}
                                singleMonthOnly= {true}
                                value={entereddate} />
                                
                                </div>
                            </div>
                        </div>
                        <div className="sliderarea">
                            
                        </div>


                        <div className="title_datearea">
                            <h2>top selling products</h2>
                            <div className="datearea">
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('MMM DD')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setTSPDate(date)}
                                singleMonthOnly= {true}
                                value={tSPDate} />
                                </div>
                            </div>
                            {(totalTSP.length===0 ? <h4>Data Not Found</h4> : <table><tbody>{totalTSP.map(d => <tr key={d.productname}><td><span></span>{d.productname}</td><td>{d.totalproduct}</td></tr>)}</tbody></table>)}
                        </div>


                        <div className="title_datearea">
                            <h2>order type summary</h2>
                            <div className="datearea">
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('MMM DD')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setOrderTypeDate(date)}
                                singleMonthOnly= {true}
                                value={orderTypeDate} />
                            </div>
                            </div>
                            {(ordertype.length===0 ? <h4>Data Not Found</h4> : <div style={{ width: 400, height: 300 }}> <PieChart orderTypeData={ordertype}/></div>)}
                            </div>


                        <div className="title_datearea">
                            <h2>Payment Summary</h2>
                            <div className="datearea">
                                <div className="iconarea">
                                <DateRangeInput
                                formatDate={date => moment(date.toLocaleString()).format('MMM DD')}
                                parseDate={str => new Date(str)}
                                onChange={(date) => setPaymentTypeDate(date)}
                                singleMonthOnly= {true}
                                value={paymentTypeDate} />
                                
                                </div>
                            </div>
                            <ChartComponent paymentTypeName={paymentTypeName} paymentTypeValue={paymentTypeValue}/>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default SalesView;

