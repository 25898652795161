// Application route path information
export const RoutePaths = {
  LOGIN: "/",
  PROFILE: "/profile",
  LOGOUT: "/logout",
  DASHBOARD: "/dashboard",
  SALES: "/sales",
  PURCHASE: "/purchases",
  EXPENSE: "/expenses",
  MISC: "/misc"
};